import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { LeadsList, UpsertLead } from './organisms';

import { ILead } from '@interfaces';
import { ERoutes, EDashboardLeadsRoutes } from '@enums';

export const Leads = (): JSX.Element => {
  const navigate = useNavigate();

  const onBackButtonClick = () => navigate(-1);

  const onLeadsListRowClick = (selectedLead: ILead) =>
    navigate(`${EDashboardLeadsRoutes.UPSERT_LEAD}?leadName=${selectedLead.name}`);

  const onSetSelectedLead = (selectedLead: ILead) =>
    navigate(`${EDashboardLeadsRoutes.UPSERT_LEAD}?leadName=${selectedLead.name}`);

  return (
    <Routes>
      <Route
        path={EDashboardLeadsRoutes.LIST}
        element={<LeadsList onTableRowClick={onLeadsListRowClick} />}
      />
      <Route
        path={EDashboardLeadsRoutes.UPSERT_LEAD}
        element={<UpsertLead onBackClick={onBackButtonClick} setSelectedLead={onSetSelectedLead} />}
      />
      <Route path={ERoutes.ANY} element={<Navigate replace to={ERoutes.NOT_FOUND} />} />
    </Routes>
  );
};
