import { BrowserRouter } from 'react-router-dom';
import { ToastProvider, ConfirmationModalProvider } from '@amperio/amperio-ui';

import {
  LocaleProvider,
  QueryParamProvider,
  QueryClientProvider,
  Auth0ProviderWithHistory,
} from '..';

import { IAppProviderProps } from './app-provider.types';

export const AppProvider = ({ children }: IAppProviderProps): JSX.Element => (
  <BrowserRouter>
    <QueryParamProvider>
      <ToastProvider>
        <ConfirmationModalProvider>
          <Auth0ProviderWithHistory>
            <LocaleProvider>
              <QueryClientProvider>{children}</QueryClientProvider>
            </LocaleProvider>
          </Auth0ProviderWithHistory>
        </ConfirmationModalProvider>
      </ToastProvider>
    </QueryParamProvider>
  </BrowserRouter>
);
