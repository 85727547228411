import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button, LogoutIcon } from '@amperio/amperio-ui';
import { StringParam, useQueryParam } from 'use-query-params';

import { Error } from '@domain/error';

import { ERoutes } from '@enums';
import { useTranslation } from '@utils';
import { translationStrings } from './forbidden.defaults';

export const ForbiddenPage = (): JSX.Element => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const translations = useTranslation(translationStrings);
  const [messageQueryParam] = useQueryParam('message', StringParam);

  const redirectToHomepage = () => navigate(`/${ERoutes.DASHBOARD}`);

  const message = messageQueryParam ?? translations.errorPageForbiddenMessage;

  return (
    <Error code="403" message={message}>
      <Button variant="contained" onClick={redirectToHomepage}>
        {translations.errorPageForbiddenBackToHomepageButton}
      </Button>
      <Button onClick={() => logout()} variant="outlined" endIcon={<LogoutIcon />}>
        {translations.commonButtonsLogout}
      </Button>
    </Error>
  );
};
