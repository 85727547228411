import {
  Icon,
  Chip,
  DataGrid,
  LeadsIcon,
  Typography,
  MeetingsIcon,
  HeatPumpsIcon,
  HeatPlatesIcon,
  SolarPanelsIcon,
} from '@amperio/amperio-ui';
import { useState, Children } from 'react';

import {
  chunkOfData,
  translationStrings,
  eventStatusToChipColorMapper,
  getEventStatusToTranslationsMapper,
} from './events-table.defaults';
import { useTranslation } from '@utils';

import { EEventType, EProductCategory } from '@enums';

import './events-table.styles.scss';

export const EventsTable = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const translations = useTranslation(translationStrings);

  const eventStatusToTranslationsMapper = getEventStatusToTranslationsMapper(translations);

  return (
    <div className="events-table-wrapper">
      <Typography strong as="h3" className="events-table-wrapper__header">
        {translations.dashboardAccountsSectionEventsTableHeader}
      </Typography>
      <div className="events-table-wrapper__table">
        <DataGrid
          isLoading={isLoading}
          getDataFromServer={() =>
            new Promise((resolve) => {
              setIsLoading(true);

              setTimeout(() => resolve(chunkOfData), Math.max(Math.random() * 3000, 300));
            }).then((data) => {
              setIsLoading(false);
              return data;
            })
          }
          columns={[
            {
              minWidth: 150,
              field: 'date',
              headerName: translations.dashboardAccountsSectionEventsTableDate,
            },
            {
              minWidth: 150,
              field: 'products',
              headerName: translations.dashboardAccountsSectionEventsTableProducts,
              renderBodyCell: (value) => (
                <>
                  {Children.toArray(
                    (JSON.parse(value) || []).map((_element: string) => (
                      <Icon fill="none" stroke="primary">
                        {_element === EProductCategory.FOIL_HEATERS ? <HeatPlatesIcon /> : null}
                        {_element === EProductCategory.HEATING_PUMPS ? <HeatPumpsIcon /> : null}
                        {_element === EProductCategory.PHOTOVOLTAICS ? <SolarPanelsIcon /> : null}
                      </Icon>
                    )),
                  )}
                </>
              ),
            },
            {
              minWidth: 100,
              field: 'type',
              headerName: translations.dashboardAccountsSectionEventsTableType,
              renderBodyCell: (value) => (
                <Icon fill="none" stroke="primary">
                  {(value as never) === EEventType.CALL ? <LeadsIcon /> : null}
                  {(value as never) === EEventType.MEETING ? <MeetingsIcon /> : null}
                </Icon>
              ),
            },
            {
              minWidth: 200,
              field: 'status',
              headerName: translations.dashboardAccountsSectionEventsTableStatus,
              renderBodyCell: (value) => (
                <Chip
                  size="small"
                  color={eventStatusToChipColorMapper[value]}
                  label={eventStatusToTranslationsMapper[value]}
                />
              ),
            },
            {
              minWidth: 400,
              field: 'notes',
              headerName: translations.dashboardAccountsSectionEventsTableNotes,
            },
          ]}
        />
      </div>
    </div>
  );
};
