import { ELocale, ELeadStatus } from '@enums';

export const DEFAULT_LOCALE: ELocale =
  (process.env.REACT_APP_DEFAULT_LOCALE as ELocale) ?? ELocale.EN;

/**
 * openid: This scope informs the Auth0 Authorization Server that the Client is making
 * an OpenID Connect (OIDC) request to verify the user's identity. OpenID Connect is
 * an authentication protocol.
 *
 * profile: This scope value requests access to the user's default profile information,
 * such as name, nickname, and picture.
 *
 * email: This scope value requests access to the email and email_verified information.
 */
export const AUTH0_SCOPES = 'email openid profile';

export const leadStatusToChipColorMapping: Record<string, TChipColor> = {
  [ELeadStatus.NEW]: 'green',
  [ELeadStatus.INVALID]: 'red',
  [ELeadStatus.CALLBACK]: 'brown',
  [ELeadStatus.INTERESTED]: 'blue',
  [ELeadStatus.RESIGNATION]: 'gray',
  [ELeadStatus.DUPLICATE]: 'orange',
};
