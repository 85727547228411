import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon, Typography, ArrowLeftIcon } from '@amperio/amperio-ui';

import { useTranslation, getTranslationKeyAndPathForHeaderFromUrl } from '@utils';

import './mobile-header.styles.scss';

export const MobileHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const translation = useMemo(() => getTranslationKeyAndPathForHeaderFromUrl(pathname), [pathname]);
  const translations = useTranslation([`dashboard.header.${translation.path}`]);

  return (
    <div className="mobile-header-wrapper">
      <Icon pointer fill="none" stroke="primary" onClick={() => navigate(-1)}>
        <ArrowLeftIcon />
      </Icon>
      <Typography strong as="h6">
        {translations[translation.key]}
      </Typography>
    </div>
  );
};
