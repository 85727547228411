import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { StringParam, useQueryParam } from 'use-query-params';

import { FullScreenLoading } from '@domain/loading';

export const LoginPage = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const [invitationQueryParam] = useQueryParam('invitation', StringParam);
  const [organizationQueryParam] = useQueryParam('organization', StringParam);

  useEffect(() => {
    loginWithRedirect({
      invitation: invitationQueryParam || undefined,
      organization: organizationQueryParam || undefined,
    });
  }, [invitationQueryParam, organizationQueryParam, loginWithRedirect]);

  return <FullScreenLoading />;
};
