import formsTranslations from './forms.translations';
import commonTranslations from './common.translations';
import calendarTranslations from './calendar.translations';
import errorPageTranslations from './error-page.translations';
import dashboardLeadsTranslations from './dashboard-leads.translations';
import dashboardHeaderTranslations from './dashboard-header.translations';
import dashboardAccountsTranslations from './dashboard-accounts.translations';
import dashboardEventsTranslations from './dashboard-events.translations';

export default {
  ...formsTranslations,
  ...commonTranslations,
  ...calendarTranslations,
  ...errorPageTranslations,
  ...dashboardLeadsTranslations,
  ...dashboardHeaderTranslations,
  ...dashboardEventsTranslations,
  ...dashboardAccountsTranslations,
};
