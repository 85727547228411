import { useCallback } from 'react';
import { useConfirmationModal } from '@amperio/amperio-ui';

import { useBlocker } from '@utils';

export function usePrompt(title: string, message: string, when = true) {
  const { confirm } = useConfirmationModal();

  const blocker = useCallback(
    async (tx) => {
      confirm({
        contentProps: {
          style: {
            paddingLeft: 0,
            textAlign: 'center',
          },
        },
        title: title || '',
        confirmationButtonProps: { autoFocus: true },
        description: message || '',
      }).then(() => tx.retry());
    },
    [message],
  );

  useBlocker(blocker, when);
}
