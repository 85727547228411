import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { UpsertEvent } from '../shared';
import { EventsView } from './organisms';

import { ERoutes, EDashboardEventsRoutes } from '@enums';

export const Events = (): JSX.Element => {
  const navigate = useNavigate();

  const onBackButtonClick = () => navigate(-1);

  const onCalendarEventClick = (eventId: string) =>
    navigate(`${EDashboardEventsRoutes.UPSERT}?eventId=${eventId}`);

  const onAddNewEventButtonClick = () => navigate(EDashboardEventsRoutes.UPSERT);

  return (
    <Routes>
      <Route
        path={EDashboardEventsRoutes.LIST}
        element={
          <EventsView
            onCalendarEventClick={onCalendarEventClick}
            onAddNewEventButtonClick={onAddNewEventButtonClick}
          />
        }
      />
      <Route
        path={EDashboardEventsRoutes.UPSERT}
        element={<UpsertEvent onBackClick={onBackButtonClick} />}
      />
      <Route path={ERoutes.ANY} element={<Navigate replace to={ERoutes.NOT_FOUND} />} />
    </Routes>
  );
};
