import { camelCase } from '@amperio/amperio-ui';

import { useLocale } from '@utils';
import { TMessages } from './use-translation.types';

export const useTranslation = <T extends readonly string[]>(key: T): TMessages<T> => {
  const { formatMessage } = useLocale();

  return Object.fromEntries(
    key.map((translationKey) => [camelCase(translationKey), formatMessage({ id: translationKey })]),
  ) as TMessages<T>;
};
