export default {
  // list
  'dashboard.leads.list.name.column.label': 'Name',
  'dashboard.leads.list.phone.number.column.label': 'Phone number',
  'dashboard.leads.list.date.column.label': 'Date',
  'dashboard.leads.list.partner.column.label': 'Partner',
  'dashboard.leads.list.country.column.label': 'Country',
  'dashboard.leads.list.region.column.label': 'Region',
  'dashboard.leads.list.city.column.label': 'City',
  'dashboard.leads.list.status.column.label': 'Status',
  'dashboard.leads.list.is.valid.column.label': 'Is valid?',
};
