export default {
  // events
  'dashboard.header.events.list': 'Events',
  'dashboard.header.events.upsert': 'Events / Upsert event',

  // accounts
  'dashboard.header.accounts.list': 'Accounts',
  'dashboard.header.accounts.upsert-account': 'Accounts / Upsert account',
  'dashboard.header.accounts.upsert-event': 'Accounts / Upsert account / Upsert event',

  // leads
  'dashboard.header.leads.list': 'Leads',
  'dashboard.header.leads.upsert-lead': 'Leads / Upsert lead',
};
