import {
  Icon,
  Avatar,
  Typography,
  LogoutIcon,
  OfflineIcon,
  OwlIcon,
  ToggleMenuButton,
} from '@amperio/amperio-ui';
import { useState } from 'react';
import classnames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { Offline } from 'react-detect-offline';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from '@utils';
import { translationStrings } from './mobile-topbar.defaults';

import { IMobileTopbarProps } from './mobile-topbar.types';

import './mobile-topbar.styles.scss';

export const MobileTopbar = ({ options }: IMobileTopbarProps): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const translations = useTranslation(translationStrings);

  const toggleMenu = () => setIsOpen(!isOpen);

  const redirect = (isActive: boolean, path: string) => () => {
    if (!isActive) {
      navigate(path);
    }

    toggleMenu();
  };

  return (
    <div className="mobile-topbar-wrapper">
      <OwlIcon className="mobile-topbar-wrapper__logo" />
      <div className="mobile-topbar-wrapper__right-container">
        <Offline polling={false}>
          <Icon fill="none" stroke="white">
            <OfflineIcon />
          </Icon>
        </Offline>
        <Avatar size="small" src={user?.picture} />
        <ToggleMenuButton isActive={isOpen} onClick={toggleMenu} />
      </div>
      <div
        className={classnames('mobile-topbar-wrapper__sidebar', {
          'mobile-topbar-wrapper__sidebar--hidden': !isOpen,
        })}
      >
        <span>
          {options.map(({ label, redirectTo, toMatchWith, IconComponent }) => {
            const shouldBlockRoute = pathname.includes(redirectTo);
            const isActive = pathname.includes(toMatchWith || redirectTo);

            return (
              <div
                key={redirectTo}
                onClick={redirect(shouldBlockRoute, redirectTo)}
                className={classnames('mobile-topbar-wrapper__sidebar__option', {
                  'mobile-topbar-wrapper__sidebar__option--active': isActive,
                })}
              >
                <IconComponent className="mobile-topbar-wrapper__sidebar__icon" />
                <Typography as="h6" color="white">
                  {label}
                </Typography>
              </div>
            );
          })}
        </span>
        <div
          key="logout-button"
          onClick={() => logout()}
          className="mobile-topbar-wrapper__sidebar__option"
        >
          <LogoutIcon className="mobile-topbar-wrapper__sidebar__icon mobile-topbar-wrapper__sidebar__icon--logout" />
          <Typography as="h6" color="white">
            {translations.commonButtonsLogout}
          </Typography>
        </div>
      </div>
    </div>
  );
};
