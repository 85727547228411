import { ILead } from '@interfaces';
import { ELeadStatus } from '@enums';

export const dataSourceMock: ILead[] = [
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.CALLBACK,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.DUPLICATE,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.INTERESTED,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.INVALID,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.NEW,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.RESIGNATION,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.RESIGNATION,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.CALLBACK,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.DUPLICATE,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.INTERESTED,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.INVALID,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.NEW,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.RESIGNATION,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.CALLBACK,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.DUPLICATE,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.INTERESTED,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.INVALID,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.NEW,
  },
  {
    name: 'John Smith',
    phoneNumber: '667981765',
    city: 'Kraków',
    country: 'Polska',
    region: 'Małopolskie',
    date: '14.11.2021',
    partner: 'Chuck Norris',
    status: ELeadStatus.RESIGNATION,
  },
];

export const translationStrings = [
  'dashboard.leads.list.name.column.label',
  'dashboard.leads.list.phone.number.column.label',
  'dashboard.leads.list.date.column.label',
  'dashboard.leads.list.partner.column.label',
  'dashboard.leads.list.country.column.label',
  'dashboard.leads.list.region.column.label',
  'dashboard.leads.list.city.column.label',
  'dashboard.leads.list.status.column.label',
  'dashboard.leads.list.is.valid.column.label',
  'common.lead.status.new',
  'common.lead.status.invalid',
  'common.lead.status.callback',
  'common.lead.status.duplicate',
  'common.lead.status.interested',
  'common.lead.status.resignation',
] as const;

export const chunkOfData = [
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
];
