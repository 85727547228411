import {
  Paper,
  Typography,
  useDeviceDetect,
  DropdownWithController,
  TextInputWithController,
} from '@amperio/amperio-ui';

import { useTranslation } from '@utils';
import { translationStrings, getStatusAfterEventDropdownOptions } from './summary-form.defaults';

import { ISummaryFormProps } from './summary-form.types';

import './summary-form.styles.scss';

export const SummaryForm = ({ control, isEditMode }: ISummaryFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const statusAfterEventDropdownOptions = getStatusAfterEventDropdownOptions(translations);

  return (
    <Paper padding={deviceData.isMobile ? 'sm' : 'md'} className="summary-form-wrapper">
      <Typography as={deviceData.isMobile ? 'h3' : 'h2'} strong>
        {translations.formSummaryHeader}
      </Typography>
      <div className="summary-form-wrapper__form">
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            labelId: 'summary-dropdown-label',
            options: statusAfterEventDropdownOptions,
            label: translations.formSummaryStatusLabel,
          }}
          controllerProps={{
            control,
            name: 'summary.eventStatus',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'summary.notes',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 500,
                message: translations.formSummaryNotesMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formSummaryNotesLabel,
          }}
        />
      </div>
    </Paper>
  );
};
