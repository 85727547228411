import { Paper, Typography, useDeviceDetect, TextInputWithController } from '@amperio/amperio-ui';

import { useTranslation } from '@utils';
import { translationStrings } from './leads-form.defaults';

import { ILeadsFormProps } from './leads-form.types';

import './leads-form.styles.scss';

export const LeadsForm = ({ control, isEditMode }: ILeadsFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  return (
    <Paper padding={deviceData.isMobile ? 'sm' : 'md'} className="leads-form-wrapper">
      <Typography as={deviceData.isMobile ? 'h4' : 'h2'} strong>
        {translations.formLeadHeader}
      </Typography>
      <div className="leads-form-wrapper__form">
        <TextInputWithController
          controllerProps={{
            control,
            name: 'name',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 100,
                message: translations.formLeadNameMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formLeadNameLabel,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'surname',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 100,
                message: translations.formLeadSurnameMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formLeadSurnameLabel,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'phoneNumber',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              pattern: {
                value: /^(\d{9}|\d{12})$/,
                message: translations.formLeadPhoneNumberPatternMatchError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formLeadPhoneNumberLabel,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'zipCode',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              pattern: {
                value: /^\d{1,5}[\d -]?\d{1,5}$/,
                message: translations.formLeadZipCodePatternMatchError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formLeadZipCodeLabel,
          }}
        />
      </div>
    </Paper>
  );
};
