export default {
  // buttons
  'common.buttons.back': 'Back',
  'common.buttons.edit': 'Edit',
  'common.buttons.save': 'Save',
  'common.buttons.cancel': 'Cancel',
  'common.buttons.logout': 'Logout',

  // form
  'common.form.field.error.required': 'Field is required',
  'common.form.field.error.negative.number': 'Value cannot be negative',

  // prompt
  'common.prompt.unsaved.title': 'Unsaved changes',
  'common.prompt.unsaved.cancel': 'Are you sure you want to cancel?',
  'common.prompt.unsaved.leave': 'Are you sure you want to leave?',

  // months
  'common.month.january': 'January',
  'common.month.february': 'February',
  'common.month.march': 'March',
  'common.month.april': 'April',
  'common.month.may': 'May',
  'common.month.june': 'June',
  'common.month.july': 'July',
  'common.month.august': 'August',
  'common.month.september': 'September',
  'common.month.october': 'October',
  'common.month.november': 'November',
  'common.month.december': 'December',

  // event status
  'common.event.status.sale': 'Sale',
  'common.event.status.missed': 'Missed',
  'common.event.status.planned': 'Planned',
  'common.event.status.rejected': 'Rejected',
  'common.event.status.resignation': 'Resignation',
  'common.event.status.bid.submitted': 'Bid submitted',
  'common.event.status.sales.opportunity': 'Sales opportunity',

  // lead status
  'common.lead.status.new': 'New',
  'common.lead.status.invalid': 'Invalid',
  'common.lead.status.callback': 'Callback',
  'common.lead.status.duplicate': 'Duplicate',
  'common.lead.status.interested': 'Interested',
  'common.lead.status.resignation': 'Resignation',
};
