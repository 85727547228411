import { capitalize } from '@amperio/amperio-ui';

import { EMonth } from '@enums';

export const getMonthTranslationKeyByMonthId = (monthId: number) => {
  const absMonthId = Math.abs(monthId);
  const monthNamesFromEnums = Object.values(EMonth);
  const monthName = monthNamesFromEnums[absMonthId] || EMonth.JANUARY;
  const capitalizedMonthName = capitalize(monthName);

  return `commonMonth${capitalizedMonthName}`;
};
