import { stringArrayToDropdownOptions } from '@amperio/amperio-ui';

import { IAddressFormFields } from '../../interfaces';

export const translationStrings = [
  'common.form.field.error.required',
  'common.form.field.error.negative.number',
  'form.address.header',
  'form.address.region.label',
  'form.address.district.label',
  'form.address.city.label',
  'form.address.city.max.length.error',
  'form.address.street.label',
  'form.address.street.max.length.error',
  'form.address.zip.code.label',
  'form.address.zip.code.pattern.match.error',
  'form.address.house.number.label',
] as const;

export const regionDistrictForSpain: Record<string, string[]> = {
  Galicia: ['A Coruña', 'Lugo', 'Ourense', 'Pontevedra'],
  'Principado de Asturias': ['Asturias'],
  Cantabria: ['Cantabria'],
  'País Vasco': ['Álava', 'Guipúzcoa', 'Vizcaya'],
  'Comunidad Foral de Navarra': ['Navarra'],
  'La Rioja': ['La Rioja'],
  Aragón: ['Huesca', 'Teruel', 'Zaragoza'],
  'Comunidad de Madrid': ['Madrid'],
  'Castilla y León': [
    'Ávila',
    'Burgos',
    'León',
    'Palencia',
    'Salamanca',
    'Segovia',
    'Soria',
    'Valladolid',
    'Zamora',
  ],
  'Castilla-La Mancha': ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara', 'Toledo'],
  Extremadura: ['Badajoz', 'Cáceres'],
  Cataluña: ['Barcelona', 'Girona', 'Lleida', 'Tarragona'],
  'Comunidad Valenciana': ['Alicante / Alacant', 'Castellón / Castelló', 'Valencia / València'],
  Balears: ['Illes Balears'],
  Andalucía: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla'],
  'Región de Murcia': ['Murcia'],
  Canarias: ['Palmas', 'Santa Cruz de Tenerife'],
};

export const regionDropdownOptions = stringArrayToDropdownOptions(
  Object.keys(regionDistrictForSpain),
);

export const getDistrictDropdownOptions = (region: string) =>
  stringArrayToDropdownOptions(regionDistrictForSpain[region] || []);

export const addressFormDefaultFormFields: IAddressFormFields = {
  city: '',
  region: '',
  street: '',
  zipCode: '',
  district: '',
  houseNumber: '',
};
