import {
  Paper,
  Typography,
  useDeviceDetect,
  DropdownWithController,
  DatePickerWithController,
} from '@amperio/amperio-ui';

import {
  translationStrings,
  ownerDropdownOptions,
  customerDropdownOptions,
  meetingHourDropdownOptions,
  getEventTypeDropdownOptions,
  meetingLengthDropdownOptions,
} from './general-form.defaults';
import { useTranslation } from '@utils';

import { IGeneralFormProps } from './general-form.types';

import './general-form.styles.scss';

export const GeneralForm = ({ control, isEditMode }: IGeneralFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const eventTypeDropdownOptions = getEventTypeDropdownOptions(translations);

  return (
    <Paper padding={deviceData.isMobile ? 'sm' : 'md'} className="general-form-wrapper">
      <Typography as={deviceData.isMobile ? 'h3' : 'h2'} strong>
        {translations.formGeneralHeader}
      </Typography>
      <div className="general-form-wrapper__form">
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            options: customerDropdownOptions,
            labelId: 'customer-dropdown-label',
            label: translations.formGeneralCustomerLabel,
          }}
          controllerProps={{
            control,
            name: 'general.customer',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            options: eventTypeDropdownOptions,
            labelId: 'event-type-dropdown-label',
            label: translations.formGeneralEventTypeLabel,
          }}
          controllerProps={{
            control,
            name: 'general.eventType',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            options: ownerDropdownOptions,
            labelId: 'owner-dropdown-label',
            label: translations.formGeneralOwnerLabel,
          }}
          controllerProps={{
            control,
            name: 'general.owner',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <DatePickerWithController
          componentProps={{ disabled: !isEditMode }}
          label={translations.formGeneralSelectMeetingDateLabel}
          controllerProps={{
            control,
            name: 'general.date',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            options: meetingHourDropdownOptions,
            labelId: 'meeting-hour-dropdown-label',
            label: translations.formGeneralSelectMeetingHourLabel,
          }}
          controllerProps={{
            control,
            name: 'general.meetingHour',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            options: meetingLengthDropdownOptions,
            labelId: 'meeting-length-dropdown-label',
            label: translations.formGeneralSelectMeetingLengthLabel,
          }}
          controllerProps={{
            control,
            name: 'general.meetingLengthInMinutes',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
      </div>
    </Paper>
  );
};
