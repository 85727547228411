import {
  Fab,
  Icon,
  Avatar,
  Switch,
  Button,
  Dropdown,
  PlusIcon,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  ArrowLeftIcon,
  ArrowRightIcon,
  useDeviceDetect,
  FormControlLabel,
} from '@amperio/amperio-ui';
import classnames from 'classnames';
import { createRef, useState } from 'react';

import { EventsCalendar } from '../../molecules';

import { translationStrings } from './events-view.defaults';
import { useTranslation, getMonthTranslationKeyByMonthId } from '@utils';

import { TFullCalendar } from '@interfaces';
import { IEventsViewProps } from './events-view.types';

import './events-view.styles.scss';

export const EventsView = ({
  onCalendarEventClick,
  onAddNewEventButtonClick,
}: IEventsViewProps): JSX.Element => {
  const [seller, setSeller] = useState('');
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const [monthNumber, setMonthNumber] = useState(new Date().getMonth());
  const [shouldRenderWeekends, setShouldRenderWeekends] = useState(false);

  const calendarRef = createRef<TFullCalendar>();

  const monthNameTranslationKey = getMonthTranslationKeyByMonthId(monthNumber);

  return (
    <div className="events-view-wrapper">
      <section className="events-view-wrapper__header">
        <div
          className={classnames('header__calendar-actions', {
            'header__calendar-actions--mobile': deviceData.isMobile,
          })}
        >
          <div
            className={classnames('calendar-actions__arrows', {
              'calendar-actions__arrows--mobile': deviceData.isMobile,
            })}
          >
            <IconButton onClick={() => calendarRef.current?.getApi().prev()}>
              <Icon pointer fill="none" stroke="primary">
                <ArrowLeftIcon />
              </Icon>
            </IconButton>
            <IconButton onClick={() => calendarRef.current?.getApi().next()}>
              <Icon pointer fill="none" stroke="primary">
                <ArrowRightIcon />
              </Icon>
            </IconButton>
          </div>
          <Typography as={deviceData.isDesktop ? 'h1' : 'h4'} strong>
            {translations[monthNameTranslationKey as never]}
          </Typography>
          <FormControlLabel
            labelPlacement="start"
            label={translations.dashboardEventsListHeaderCalendarSwitch}
            control={
              <Switch
                defaultChecked
                className="header__switch"
                value={shouldRenderWeekends}
                onChange={() => setShouldRenderWeekends(!shouldRenderWeekends)}
              />
            }
          />
        </div>
        <FormControl variant="standard" className="events-view-wrapper__dropdown">
          <InputLabel id="partner-dropdown-id">
            {translations.dashboardEventsHeaderSelectSeller}
          </InputLabel>
          <Dropdown
            value={seller}
            autoWidth={false}
            variant="standard"
            labelId="seller-dropdown-id"
            onChange={(event) => setSeller((event?.target?.value as string) || '')}
            options={[
              {
                label: (
                  <div className="events-view-wrapper__dropdown-option">
                    <Avatar size="small" />
                    <Typography>John Smith</Typography>
                  </div>
                ),
                value: 'john-smith',
              },
              {
                label: (
                  <div className="events-view-wrapper__dropdown-option">
                    <Avatar size="small" />
                    <Typography>Bob Budowniczy</Typography>
                  </div>
                ),
                value: 'bob-budowniczy',
              },
            ]}
          />
        </FormControl>
        <div className="header__events-actions">
          {deviceData.isMobile ? (
            <Fab onClick={onAddNewEventButtonClick}>
              <Icon stroke="white">
                <PlusIcon />
              </Icon>
            </Fab>
          ) : (
            <Button variant="contained" startIcon={<PlusIcon />} onClick={onAddNewEventButtonClick}>
              {translations.dashboardEventsListHeaderAddEventButton}
            </Button>
          )}
        </div>
      </section>
      <EventsCalendar
        calendarRef={calendarRef}
        setMonthNumber={setMonthNumber}
        onEventClick={onCalendarEventClick}
        shouldRenderWeekends={shouldRenderWeekends}
      />
    </div>
  );
};
