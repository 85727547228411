import { Children } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { getConditionalRoute } from './utils';

import { ERoutes } from '@enums';
import { routingConfig } from './routing.config';

export const Routing = (): JSX.Element => (
  <Routes>
    {Children.toArray(
      Object.keys(routingConfig).map((path) => {
        const currentRoute = routingConfig[path as ERoutes];

        let parsedPath = path;
        if (parsedPath !== ERoutes.ANY) {
          parsedPath = currentRoute.isDescendantWrapper ? `${parsedPath}/*` : parsedPath;
        }

        const element = getConditionalRoute({ ...currentRoute, path: parsedPath });

        return <Route {...currentRoute} path={parsedPath} element={element} />;
      }),
    )}
    <Route path="/" element={<Navigate to={ERoutes.DASHBOARD} />} />
  </Routes>
);
