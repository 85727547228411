import classnames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Tooltip, OwlIcon, IconButton } from '@amperio/amperio-ui';

import { IDesktopSidebarProps } from './desktop-sidebar.types';
import { EDashboardRoutes, EDashboardAccountsRoutes } from '@enums';

import './desktop-sidebar.styles.scss';

export const DesktopSidebar = ({ options }: IDesktopSidebarProps): JSX.Element => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redirect = (isActive: boolean, path: string) => () => !isActive && navigate(path);

  return (
    <aside className="desktop-sidebar-wrapper">
      <span>
        <OwlIcon
          className="desktop-sidebar-wrapper__logo"
          onClick={redirect(
            pathname.includes(EDashboardRoutes.ACCOUNTS),
            `${EDashboardRoutes.ACCOUNTS}/${EDashboardAccountsRoutes.LIST}`,
          )}
        />
        <div className="desktop-sidebar-wrapper__icons">
          {options.map(({ label, redirectTo, toMatchWith, IconComponent }) => {
            const shouldBlockRoute = pathname.includes(redirectTo);
            const isActive = pathname.includes(toMatchWith || redirectTo);

            return (
              <Tooltip title={label} placement="right" key={redirectTo}>
                <IconButton
                  fill="none"
                  size="large"
                  stroke="white"
                  onClick={redirect(shouldBlockRoute, redirectTo)}
                >
                  <IconComponent
                    className={classnames('desktop-sidebar-wrapper__icon', {
                      'desktop-sidebar-wrapper__icon--active': isActive,
                    })}
                  />
                </IconButton>
              </Tooltip>
            );
          })}
        </div>
      </span>
      <Avatar shadow="large" src={user?.picture} />
    </aside>
  );
};
