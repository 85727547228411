import { useState } from 'react';
import { Chip, DataGrid, Checkbox, Typography } from '@amperio/amperio-ui';

import { leadStatusToChipColorMapping } from '@infrastructure';
import { chunkOfData, translationStrings } from './leads-table.defaults';
import { useTranslation, getLeadStatusToTranslationsMapping } from '@utils';

import { ILead } from '@interfaces';
import { ILeadsTableProps } from './leads-table.types';

import './leads-table.styles.scss';

export const LeadsTable = ({ onRowClick }: ILeadsTableProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const translations = useTranslation(translationStrings);

  const leadStatusToTranslationsMapping = getLeadStatusToTranslationsMapping(translations);

  return (
    <DataGrid
      isLoading={isLoading}
      className="leads-table-wrapper"
      onBodyRow={(record: unknown) => ({
        pointer: true,
        onClick: () => onRowClick(record as ILead),
      })}
      getContainer={() => document.getElementById('leads-list-wrapper-id')}
      getDataFromServer={() =>
        new Promise((resolve) => {
          setIsLoading(true);

          setTimeout(() => resolve(chunkOfData), Math.max(Math.random() * 3000, 300));
        }).then((data) => {
          setIsLoading(false);
          return data;
        })
      }
      columns={[
        {
          minWidth: 170,
          field: 'name',
          headerName: translations.dashboardLeadsListNameColumnLabel,
          renderBodyCell: (value) => (
            <Typography strong as="span">
              {value}
            </Typography>
          ),
        },
        {
          minWidth: 150,
          field: 'phoneNumber',
          headerName: translations.dashboardLeadsListPhoneNumberColumnLabel,
        },
        {
          minWidth: 100,
          field: 'date',
          headerName: translations.dashboardLeadsListDateColumnLabel,
        },
        {
          minWidth: 170,
          field: 'partner',
          headerName: translations.dashboardLeadsListPartnerColumnLabel,
        },
        {
          minWidth: 150,
          field: 'country',
          headerName: translations.dashboardLeadsListCountryColumnLabel,
        },
        {
          minWidth: 150,
          field: 'region',
          headerName: translations.dashboardLeadsListRegionColumnLabel,
        },
        {
          minWidth: 140,
          field: 'city',
          headerName: translations.dashboardLeadsListCityColumnLabel,
        },
        {
          minWidth: 140,
          field: 'status',
          headerName: translations.dashboardLeadsListStatusColumnLabel,
          renderBodyCell: (value) => (
            <Chip
              size="small"
              color={leadStatusToChipColorMapping[value]}
              label={leadStatusToTranslationsMapping[value]}
            />
          ),
        },
        {
          minWidth: 75,
          headerName: translations.dashboardLeadsListIsValidColumnLabel,
          renderBodyCell: () => (
            <Checkbox size="small" onClick={(event) => event.stopPropagation()} />
          ),
        },
      ]}
    />
  );
};
