import { LeadsIcon, AccountsIcon, MeetingsIcon } from '@amperio/amperio-ui';

import {
  EDashboardRoutes,
  EDashboardLeadsRoutes,
  EDashboardEventsRoutes,
  EDashboardAccountsRoutes,
} from '@enums';
import { ISidebarOption } from '@domain/dashboard/molecules';

export const translationStrings = [
  'dashboard.header.leads.list',
  'dashboard.header.events.list',
  'dashboard.header.accounts.list',
] as const;

export const getSidebarOptions = (translations: Record<string, string>): ISidebarOption[] => [
  {
    IconComponent: AccountsIcon,
    toMatchWith: EDashboardRoutes.ACCOUNTS,
    label: translations.dashboardHeaderAccountsList,
    redirectTo: `${EDashboardRoutes.ACCOUNTS}/${EDashboardAccountsRoutes.LIST}`,
  },
  {
    IconComponent: MeetingsIcon,
    toMatchWith: EDashboardRoutes.EVENTS,
    label: translations.dashboardHeaderEventsList,
    redirectTo: `${EDashboardRoutes.EVENTS}/${EDashboardEventsRoutes.LIST}`,
  },
  {
    IconComponent: LeadsIcon,
    toMatchWith: EDashboardRoutes.LEADS,
    label: translations.dashboardHeaderLeadsList,
    redirectTo: `${EDashboardRoutes.LEADS}/${EDashboardLeadsRoutes.LIST}`,
  },
];
