import { IUpsertEventFormFields } from '../../interfaces';

import {
  addressFormDefaultFormFields,
  generalFormDefaultFormFields,
  summaryFormDefaultFormFields,
  productCategoriesFormDefaultFormFields,
} from '../../molecules';

export const translationStrings = [
  'common.buttons.edit',
  'common.buttons.save',
  'common.buttons.cancel',
  'common.prompt.unsaved.title',
  'common.prompt.unsaved.cancel',
  'common.buttons.back',
  'common.prompt.unsaved.leave',
] as const;

export const defaultFormFields: IUpsertEventFormFields = {
  address: addressFormDefaultFormFields,
  general: generalFormDefaultFormFields,
  summary: summaryFormDefaultFormFields,
  productCategories: productCategoriesFormDefaultFormFields,
};
