import { ILeadsFormFields } from './leads-form.types';

export const translationStrings = [
  'form.lead.header',
  'common.form.field.error.required',
  'form.lead.name.label',
  'form.lead.name.max.length.error',
  'form.lead.surname.label',
  'form.lead.surname.max.length.error',
  'form.lead.phone.number.label',
  'form.lead.phone.number.pattern.match.error',
  'form.lead.zip.code.label',
  'form.lead.zip.code.pattern.match.error',
] as const;

export const leadsFormDefaultFormFields: ILeadsFormFields = {
  name: '',
  surname: '',
  zipCode: '',
  phoneNumber: '',
};
