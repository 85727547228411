import {
  MutationFunction,
  UseMutationOptions,
  useMutation as useMutationReactQuery,
} from 'react-query';

import { useAccessToken } from '@utils';

export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutateFn: (getAccessToken: () => Promise<string | void>) => MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
) => {
  const { getAccessToken } = useAccessToken();

  return useMutationReactQuery(mutateFn(getAccessToken), options);
};
