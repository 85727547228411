import { EEventStatus } from '@enums';
import { ISummaryFormFields } from '../../interfaces';

export const translationStrings = [
  'common.form.field.error.required',

  'form.summary.notes.label',
  'form.summary.notes.max.length.error',

  'form.summary.header',
  'form.summary.status.label',
  'common.event.status.sale',
  'common.event.status.missed',
  'common.event.status.planned',
  'common.event.status.rejected',
  'common.event.status.resignation',
  'common.event.status.bid.submitted',
  'common.event.status.sales.opportunity',
] as const;

export const getStatusAfterEventDropdownOptions = (translations: Record<string, string>) => [
  {
    value: EEventStatus.SALE,
    label: translations.commonEventStatusSale,
  },
  {
    value: EEventStatus.MISSED,
    label: translations.commonEventStatusMissed,
  },
  {
    value: EEventStatus.PLANNED,
    label: translations.commonEventStatusPlanned,
  },
  {
    value: EEventStatus.REJECTED,
    label: translations.commonEventStatusRejected,
  },
  {
    value: EEventStatus.RESIGNATION,
    label: translations.commonEventStatusResignation,
  },
  {
    value: EEventStatus.BID_SUBMITTED,
    label: translations.commonEventStatusBidSubmitted,
  },
  {
    value: EEventStatus.SALES_OPPORTUNITY,
    label: translations.commonEventStatusSalesOpportunity,
  },
];

export const summaryFormDefaultFormFields: ISummaryFormFields = {
  notes: '',
  eventStatus: EEventStatus.SALE,
};
