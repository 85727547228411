import { Button, Fab, Icon, PlusIcon, useDeviceDetect } from '@amperio/amperio-ui';

import { AccountsTable } from '../../molecules';

import { useTranslation } from '@utils';

import { IAccountsListProps } from './accounts-list.types';

import './accounts-list.styles.scss';

export const AccountsList = ({
  onTableRowClick,
  onAddNewAccountButtonClick,
}: IAccountsListProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(['dashboard.accounts.header.add.account.button'] as const);

  return (
    <div className="accounts-list-wrapper">
      {deviceData.isMobile ? (
        <Fab onClick={onAddNewAccountButtonClick}>
          <Icon stroke="white" size="large">
            <PlusIcon />
          </Icon>
        </Fab>
      ) : (
        <div className="accounts-list-wrapper__header">
          <Button variant="contained" startIcon={<PlusIcon />} onClick={onAddNewAccountButtonClick}>
            {translations.dashboardAccountsHeaderAddAccountButton}
          </Button>
        </div>
      )}
      <div id="accounts-list-wrapper-id" className="accounts-list-wrapper__table-wrapper">
        <AccountsTable onRowClick={onTableRowClick} />
      </div>
    </div>
  );
};
