import { Paper, Typography, useDeviceDetect, TextInputWithController } from '@amperio/amperio-ui';

import { useTranslation } from '@utils';
import { translationStrings } from './account-form.defaults';

import { IAccountFormProps } from './account-form.types';

import './account-form.styles.scss';

export const AccountForm = ({ control, isEditMode }: IAccountFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  return (
    <Paper padding={deviceData.isMobile ? 'sm' : 'md'} className="account-form-wrapper">
      <Typography as={deviceData.isMobile ? 'h3' : 'h2'} strong>
        {translations.formAccountHeader}
      </Typography>
      <div className="account-form-wrapper__form">
        <TextInputWithController
          controllerProps={{
            control,
            name: 'account.name',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 100,
                message: translations.formAccountNameMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formAccountNameLabel,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'account.surname',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              maxLength: {
                value: 100,
                message: translations.formAccountSurnameMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formAccountSurnameLabel,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'account.phoneNumber',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
              pattern: {
                value: /^(\d{9}|\d{12})$/,
                message: translations.formAccountPhoneNumberPatternMatchError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formAccountPhoneNumberLabel,
          }}
        />
        <TextInputWithController
          controllerProps={{
            control,
            name: 'account.email',
            rules: {
              pattern: {
                value: /^\w+@[A-Z_a-z]+?\.[A-Za-z]{2,3}$/,
                message: translations.formAccountEmailPatternMatchError,
              },
              maxLength: {
                value: 100,
                message: translations.formAccountEmailMaxLengthError,
              },
            },
          }}
          inputProps={{
            disabled: !isEditMode,
            label: translations.formAccountEmailLabel,
          }}
        />
      </div>
    </Paper>
  );
};
