import { EEventStatus, EProductCategory } from '@enums';

export const dataSourceMock = [
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.SALE,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.PLANNED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.RESIGNATION,
    seller: 'Jim Jared',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.SALES_OPPORTUNITY,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.MISSED,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.REJECTED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.SALES_OPPORTUNITY,
    seller: 'Jim Jared',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.SALE,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.PLANNED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.SALE,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.RESIGNATION,
    seller: 'Jim Jared',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.PLANNED,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.MISSED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.SALES_OPPORTUNITY,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.REJECTED,
    seller: 'Jim Jared',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.RESIGNATION,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.SALE,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.RESIGNATION,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.SALE,
    seller: 'Jim Jared',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Thomson',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.PLANNED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
];

export const translationStrings = [
  'dashboard.accounts.list.name.column.label',
  'dashboard.accounts.list.location.column.label',
  'dashboard.accounts.list.products.column.label',
  'dashboard.accounts.list.date.column.label',
  'dashboard.accounts.list.status.column.label',
  'common.event.status.sale',
  'common.event.status.missed',
  'common.event.status.planned',
  'common.event.status.rejected',
  'common.event.status.resignation',
  'common.event.status.bid.submitted',
  'common.event.status.sales.opportunity',
] as const;

export const chunkOfData = [
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
];

export const eventStatusToChipColorMapper: Record<string, TChipColor> = {
  [EEventStatus.SALE]: 'green',
  [EEventStatus.MISSED]: 'red',
  [EEventStatus.PLANNED]: 'gray',
  [EEventStatus.REJECTED]: 'orange',
  [EEventStatus.RESIGNATION]: 'brown',
  [EEventStatus.BID_SUBMITTED]: 'purple',
  [EEventStatus.SALES_OPPORTUNITY]: 'blue',
};

export const getEventStatusToTranslationsMapper = (
  translations: Record<string, string>,
): Record<string, string> => ({
  [EEventStatus.SALE]: translations.commonEventStatusSale,
  [EEventStatus.MISSED]: translations.commonEventStatusMissed,
  [EEventStatus.PLANNED]: translations.commonEventStatusPlanned,
  [EEventStatus.REJECTED]: translations.commonEventStatusRejected,
  [EEventStatus.RESIGNATION]: translations.commonEventStatusResignation,
  [EEventStatus.BID_SUBMITTED]: translations.commonEventStatusBidSubmitted,
  [EEventStatus.SALES_OPPORTUNITY]: translations.commonEventStatusSalesOpportunity,
});
