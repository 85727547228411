import { LeadsTable } from '../../molecules';

import { ILeadsListProps } from './leads-list.types';

import './leads-list.styles.scss';

export const LeadsList = ({ onTableRowClick }: ILeadsListProps): JSX.Element => (
  <div id="leads-list-wrapper-id" className="leads-list-wrapper">
    <LeadsTable onRowClick={onTableRowClick} />
  </div>
);
