import {
  Calendar,
  useDeviceDetect,
  CalendarEventLabel,
  getCalendarClassname,
  getMonthFromDatesSet,
  getDaysArrayFromDateRange,
} from '@amperio/amperio-ui';

import { useLocale, useTranslation } from '@utils';

import { calendarMockup, calendarSettings, translationStrings } from './events-calendar.defaults';

import { IEventsCalendarProps } from './events-calendar.types';

export const EventsCalendar = ({
  calendarRef,
  onEventClick,
  setMonthNumber,
  setSelectedDates,
  absenceData = [],
  shouldRenderWeekends = true,
}: IEventsCalendarProps): JSX.Element => {
  const { locale } = useLocale();
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const handleEventClick = (eventId: string) => () => onEventClick(eventId);

  return (
    <Calendar
      selectable
      firstDay={1}
      editable={true}
      dayMaxEvents={6}
      headerToolbar={{
        start: '',
        center: '',
        end: '',
      }}
      locale={locale}
      fixedWeekCount={false}
      calendarRef={calendarRef}
      eventSources={calendarMockup}
      eventClick={handleEventClick('pepega')} // TODO - update 'pepega' with proper eventId later when we'll get data from backend
      weekends={shouldRenderWeekends}
      moreLinkText={translations.calendarPopoverMore}
      datesSet={(dateInfo) => {
        setMonthNumber(getMonthFromDatesSet(dateInfo));
      }}
      mobileTranslations={{
        expandedLabel: translations.calendarMoreExpandedLabel,
        collapsedLabel: translations.calendarMoreCollapsedLabel,
      }}
      dayCellClassNames={(day) => getCalendarClassname(day, absenceData)}
      eventContent={(event) => <CalendarEventLabel eventContent={event} />}
      select={(selectionInfo) => {
        if (setSelectedDates) {
          setSelectedDates(getDaysArrayFromDateRange(selectionInfo.start, selectionInfo.end));
        }
      }}
      {...(deviceData.isMobile ? calendarSettings.mobile : calendarSettings.desktop)}
    />
  );
};
