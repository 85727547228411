import * as pages from '@application/pages';

import { ERoutes } from '@enums';
import { IRoutingConfig } from './routing.types';

const authorizeDashboard = process.env.REACT_APP_AUTHORIZE_DASHBOARD === 'true';

export const routingConfig: Record<ERoutes, IRoutingConfig> = {
  [ERoutes.LOGIN]: {
    Component: pages.LoginPage,
  },
  [ERoutes.FORBIDDEN]: {
    Component: pages.ForbiddenPage,
  },
  [ERoutes.NOT_FOUND]: {
    Component: pages.NotFoundPage,
  },
  [ERoutes.DASHBOARD]: {
    isDescendantWrapper: true,
    authorize: authorizeDashboard,
    Component: pages.DashboardPage,
  },
  [ERoutes.ANY]: {
    Component: pages.NotFoundPage,
  },
};
