import {
  Paper,
  Typography,
  useDeviceDetect,
  DropdownWithController,
  TextInputWithController,
} from '@amperio/amperio-ui';

import {
  translationStrings,
  regionDropdownOptions,
  getDistrictDropdownOptions,
} from './address-form.defaults';
import { useTranslation } from '@utils';

import { IAddressFormProps } from './address-form.types';

import './address-form.styles.scss';

export const AddressForm = ({ watch, control, isEditMode }: IAddressFormProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const selectedRegion = watch('address.region');
  const selectedDistrict = watch('address.district');

  const districtDropdownOptions = getDistrictDropdownOptions(selectedRegion);

  return (
    <Paper padding={deviceData.isMobile ? 'sm' : 'md'} className="address-form-wrapper">
      <Typography as={deviceData.isMobile ? 'h3' : 'h2'} strong>
        {translations.formAddressHeader}
      </Typography>
      <div className="address-form-wrapper__form">
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode,
          }}
          dropdownProps={{
            options: regionDropdownOptions,
            labelId: 'region-dropdown-label',
            label: translations.formAddressRegionLabel,
          }}
          controllerProps={{
            control,
            name: 'address.region',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <DropdownWithController
          formControlProps={{
            disabled: !isEditMode || !selectedRegion,
          }}
          dropdownProps={{
            options: districtDropdownOptions,
            labelId: 'district-dropdown-label',
            label: translations.formAddressDistrictLabel,
          }}
          controllerProps={{
            control,
            name: 'address.district',
            rules: {
              required: {
                value: true,
                message: translations.commonFormFieldErrorRequired,
              },
            },
          }}
        />
        <div className="form__grid">
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.city',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                maxLength: {
                  value: 100,
                  message: translations.formAddressCityMaxLengthError,
                },
              },
            }}
            inputProps={{
              label: translations.formAddressCityLabel,
              disabled: !isEditMode || !selectedDistrict,
            }}
          />
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.street',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                maxLength: {
                  value: 100,
                  message: translations.formAddressStreetMaxLengthError,
                },
              },
            }}
            inputProps={{
              label: translations.formAddressStreetLabel,
              disabled: !isEditMode || !selectedDistrict,
            }}
          />
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.zipCode',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                pattern: {
                  value: /^\d{1,5}[\d -]?\d{1,5}$/,
                  message: translations.formAddressZipCodePatternMatchError,
                },
              },
            }}
            inputProps={{
              label: translations.formAddressZipCodeLabel,
              disabled: !isEditMode || !selectedDistrict,
            }}
          />
          <TextInputWithController
            controllerProps={{
              control,
              name: 'address.houseNumber',
              rules: {
                required: {
                  value: true,
                  message: translations.commonFormFieldErrorRequired,
                },
                min: {
                  value: 0,
                  message: translations.commonFormFieldErrorNegativeNumber,
                },
              },
            }}
            inputProps={{
              type: 'number',
              disabled: !isEditMode || !selectedDistrict,
              label: translations.formAddressHouseNumberLabel,
            }}
          />
        </div>
      </div>
    </Paper>
  );
};
