import {
  Fab,
  Icon,
  Button,
  Avatar,
  Dropdown,
  PlusIcon,
  InputLabel,
  Typography,
  FormControl,
  ArrowLeftIcon,
  useDeviceDetect,
  useConfirmationModal,
} from '@amperio/amperio-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StringParam, useQueryParam } from 'use-query-params';

import { usePrompt, useTranslation } from '@utils';
import { AddressForm, AccountForm, EventsTable } from '../../molecules';

import { defaultFormFields, translationStrings } from './upsert-account.defaults';

import { IUpsertAccountFormFields } from '../../interfaces';
import { IUpsertAccountProps } from './upsert-account.types';

import './upsert-account.styles.scss';

export const UpsertAccount = ({
  onBackClick,
  onAddEventClick,
  setSelectedAccount,
}: IUpsertAccountProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const [isEditMode, setIsEditMode] = useState(true);
  const translations = useTranslation(translationStrings);
  const [selectedPartner, setSelectedPartner] = useState('');
  const [accountNameQueryParam] = useQueryParam('accountName', StringParam);

  const { reset, watch, control, formState, handleSubmit } = useForm<IUpsertAccountFormFields>({
    mode: 'onChange',
    defaultValues: defaultFormFields,
  });

  usePrompt(
    translations.commonPromptUnsavedTitle,
    translations.commonPromptUnsavedLeave,
    formState.isDirty,
  );

  const onSubmit = (data: IUpsertAccountFormFields) => {
    if (!accountNameQueryParam) {
      // TODO - send request to the server with the new account data taken from form
      setSelectedAccount({
        seller: '???',
        location: '???',
        status: 'Awaiting',
        products: 'Heat Pump',
        date: new Date().toISOString(),
        name: `${data.account.name} ${data.account.surname}`,
      });
    }
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      title: translations.commonPromptUnsavedTitle,
      confirmationButtonProps: { autoFocus: true },
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      reset(defaultFormFields);
      toggleEditMode();
    });
  };

  return (
    <form className="upsert-account-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <header className="upsert-account-wrapper__header">
        {!deviceData.isMobile ? (
          <>
            <Button
              variant="outlined"
              onClick={onBackClick}
              disabled={isEditMode}
              startIcon={<ArrowLeftIcon />}
            >
              {translations.commonButtonsBack}
            </Button>
            <span>
              {!isEditMode ? (
                <Button variant="contained" onClick={toggleEditMode}>
                  {translations.commonButtonsEdit}
                </Button>
              ) : (
                <>
                  <Button variant="contained" disabled={!isEditMode} onClick={handleCancel}>
                    {translations.commonButtonsCancel}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!isEditMode || !formState.isDirty}
                  >
                    {translations.commonButtonsSave}
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                startIcon={<PlusIcon />}
                onClick={onAddEventClick}
                disabled={isEditMode || !accountNameQueryParam}
              >
                {translations.dashboardAccountsHeaderAddEventButton}
              </Button>
            </span>
          </>
        ) : null}
        {deviceData.isMobile && formState.isDirty ? (
          <Fab type="submit" variant="extended">
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              {translations.commonButtonsSave}
            </Typography>
          </Fab>
        ) : null}
        {deviceData.isMobile && accountNameQueryParam ? (
          <Fab onClick={onAddEventClick}>
            <Icon stroke="white" size="large">
              <PlusIcon />
            </Icon>
          </Fab>
        ) : null}
      </header>
      <FormControl variant="standard" className="upsert-account-wrapper__dropdown">
        <InputLabel id="partner-dropdown-id">
          {translations.dashboardAccountsHeaderAssignToPartner}
        </InputLabel>
        <Dropdown
          autoWidth={false}
          variant="standard"
          value={selectedPartner}
          labelId="partner-dropdown-id"
          onChange={(event) => setSelectedPartner((event?.target?.value as string) || '')}
          options={[
            {
              label: (
                <div className="upsert-account-wrapper__dropdown-option">
                  <Avatar size="small" />
                  <Typography>John Smith</Typography>
                </div>
              ),
              value: 'john-smith',
            },
            {
              label: (
                <div className="upsert-account-wrapper__dropdown-option">
                  <Avatar size="small" />
                  <Typography>Bob Budowniczy</Typography>
                </div>
              ),
              value: 'bob-budowniczy',
            },
          ]}
        />
      </FormControl>
      <section className="upsert-account-wrapper__content">
        <div className="upsert-account-wrapper__content-top">
          <AccountForm control={control} isEditMode={isEditMode} />
          <AddressForm isEditMode={isEditMode} watch={watch as never} control={control as never} />
        </div>
        {accountNameQueryParam ? <EventsTable /> : null}
      </section>
    </form>
  );
};
