import { Route, Routes, Navigate } from 'react-router-dom';

import { DashboardTemplate } from './templates';
import { Leads, Events, Accounts } from '@domain/dashboard/subdomain';

import { ERoutes, EDashboardRoutes, EDashboardAccountsRoutes } from '@enums';

export const DashboardPage = (): JSX.Element => (
  <DashboardTemplate>
    <Routes>
      <Route element={<Leads />} path={`${EDashboardRoutes.LEADS}/*`} />
      <Route element={<Events />} path={`${EDashboardRoutes.EVENTS}/*`} />
      <Route element={<Accounts />} path={`${EDashboardRoutes.ACCOUNTS}/*`} />
      <Route
        index
        element={<Navigate to={`${EDashboardRoutes.ACCOUNTS}/${EDashboardAccountsRoutes.LIST}`} />}
      />
      <Route path={ERoutes.ANY} element={<Navigate replace to={ERoutes.NOT_FOUND} />} />
    </Routes>
  </DashboardTemplate>
);
