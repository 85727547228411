import {
  stringArrayToDropdownOptions,
  generateArrayWithHoursAndMinutes,
} from '@amperio/amperio-ui';

import { EEventType } from '@enums';
import { IGeneralFormFields } from '../../interfaces';

export const translationStrings = [
  'common.form.field.error.required',
  'form.general.header',
  'form.general.customer.label',
  'form.general.event.type.label',
  'form.general.event.type.call',
  'form.general.event.type.meeting',
  'form.general.owner.label',
  'form.general.select.meeting.date.label',
  'form.general.select.meeting.hour.label',
  'form.general.select.meeting.length.label',
] as const;

export const ownerDropdownOptions = [
  { label: 'Tomasz Potężny', value: 'e52a74e7-9d14-4a3a-b69e-f94693c93083' },
  { label: 'Sebastian Sumita', value: 'a55bdc82-8af1-4bf8-980b-1cfbdeec7573' },
];

export const customerDropdownOptions = [
  { label: 'Bożena W.', value: 'ba1ebe8f-0694-484e-875c-3b21751c36c6' },
  { label: 'Michał G.', value: 'be7dbebd-e702-4968-a3af-8c3afbb590d9' },
  { label: 'Mateusz C.', value: 'fd2a9765-f8b6-46fc-a445-457264f0d33d' },
  { label: 'Marcin K.', value: 'bf23b50f-2cca-496d-bb46-11bf77948cfb' },
  { label: 'Tadeusz Z.', value: 'bc83c212-329e-4724-a596-3f63b59f44a4' },
  { label: 'Yeti K.', value: '25d05f49-97bd-4c7e-87d4-9437cb92a4b2' },
];

export const getEventTypeDropdownOptions = (translations: Record<string, string>) => [
  { label: translations.formGeneralEventTypeMeeting, value: EEventType.MEETING },
  { label: translations.formGeneralEventTypeCall, value: EEventType.CALL },
];

export const meetingLengthDropdownOptions = [
  {
    value: 15,
    label: '15m',
  },
  {
    value: 30,
    label: '30m',
  },
  {
    value: 45,
    label: '45m',
  },
  {
    value: 60,
    label: '1h',
  },
  {
    value: 75,
    label: '1h 15m',
  },
  {
    value: 90,
    label: '1h 30m',
  },
  {
    value: 105,
    label: '1h 45m',
  },
  {
    value: 120,
    label: '2h',
  },
];

const arrayWithHoursAndMinutes = generateArrayWithHoursAndMinutes(6, 22);
export const meetingHourDropdownOptions = stringArrayToDropdownOptions(arrayWithHoursAndMinutes);

export const generalFormDefaultFormFields: IGeneralFormFields = {
  owner: '',
  customer: '',
  date: new Date(),
  meetingLengthInMinutes: 15,
  eventType: EEventType.MEETING,
  meetingHour: arrayWithHoursAndMinutes[0],
};
