import {
  Fab,
  Icon,
  Button,
  PlusIcon,
  Typography,
  ArrowLeftIcon,
  useDeviceDetect,
  useConfirmationModal,
} from '@amperio/amperio-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { usePrompt, useTranslation } from '@utils';
import { GeneralForm, AddressForm, SummaryForm, ProductCategoriesForm } from '../../molecules';

import { defaultFormFields, translationStrings } from './upsert-event.defaults';

import { IUpsertEventProps } from './upsert-event.types';
import { IUpsertEventFormFields } from '../../interfaces';

import './upsert-event.styles.scss';

export const UpsertEvent = ({ onBackClick }: IUpsertEventProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const [isEditMode, setIsEditMode] = useState(true);
  const translations = useTranslation(translationStrings);

  const { reset, watch, control, formState, handleSubmit } = useForm<IUpsertEventFormFields>({
    mode: 'onChange',
    defaultValues: defaultFormFields,
  });

  usePrompt(
    translations.commonPromptUnsavedTitle,
    translations.commonPromptUnsavedLeave,
    formState.isDirty,
  );

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const onSubmit = (data: IUpsertEventFormFields) => console.log('submit result', data);

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      confirmationButtonProps: { autoFocus: true },
      title: translations.commonPromptUnsavedTitle,
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      reset(defaultFormFields);
      toggleEditMode();
    });
  };

  return (
    <form className="upsert-event-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <header className="upsert-event-wrapper__header">
        {!deviceData.isMobile ? (
          <>
            <Button
              variant="outlined"
              onClick={onBackClick}
              disabled={isEditMode}
              startIcon={<ArrowLeftIcon />}
            >
              {translations.commonButtonsBack}
            </Button>
            {!isEditMode ? (
              <Button variant="contained" onClick={() => setIsEditMode(!isEditMode)}>
                {translations.commonButtonsEdit}
              </Button>
            ) : (
              <span>
                <Button variant="contained" disabled={!isEditMode} onClick={handleCancel}>
                  {translations.commonButtonsCancel}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isEditMode || !formState.isDirty}
                >
                  {translations.commonButtonsSave}
                </Button>
              </span>
            )}
          </>
        ) : null}
        {deviceData.isMobile && formState.isDirty ? (
          <Fab type="submit" variant="extended">
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              {translations.commonButtonsSave}
            </Typography>
          </Fab>
        ) : null}
      </header>
      <section className="upsert-event-wrapper__content">
        <div className="upsert-event-wrapper__content-left">
          <GeneralForm control={control} isEditMode={isEditMode} />
          <AddressForm watch={watch as never} isEditMode={isEditMode} control={control as never} />
        </div>
        <ProductCategoriesForm watch={watch} control={control} isEditMode={isEditMode} />
        <SummaryForm control={control} isEditMode={isEditMode} />
      </section>
    </form>
  );
};
