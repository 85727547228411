import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Offline } from 'react-detect-offline';
import { useLocation } from 'react-router-dom';
import { Icon, Button, Typography, LogoutIcon, OfflineIcon } from '@amperio/amperio-ui';

import { useTranslation, getTranslationKeyAndPathForHeaderFromUrl } from '@utils';

import './desktop-header.styles.scss';

export const DesktopHeader = (): JSX.Element => {
  const { logout } = useAuth0();
  const { pathname } = useLocation();

  const translation = useMemo(() => getTranslationKeyAndPathForHeaderFromUrl(pathname), [pathname]);
  const translations = useTranslation([
    'common.buttons.logout',
    `dashboard.header.${translation.path}`,
  ]);

  const handleLogout = () => logout();

  return (
    <div className="desktop-header-wrapper">
      <Typography as="h4" strong>
        {translations[translation.key]}
      </Typography>
      <div className="desktop-header-wrapper__right-container">
        <Offline polling={false}>
          <Icon fill="none" stroke="black">
            <OfflineIcon />
          </Icon>
        </Offline>
        <Button size="small" variant="outlined" onClick={handleLogout} startIcon={<LogoutIcon />}>
          {translations.commonButtonsLogout}
        </Button>
      </div>
    </div>
  );
};
