import { IUpsertAccountFormFields } from '../../interfaces';

import { accountFormDefaultFormFields, addressFormDefaultFormFields } from '../../molecules';

export const translationStrings = [
  'common.buttons.edit',
  'common.buttons.save',
  'common.buttons.cancel',
  'common.buttons.back',
  'common.prompt.unsaved.cancel',
  'common.prompt.unsaved.leave',
  'common.prompt.unsaved.title',
  'dashboard.accounts.header.add.event.button',
  'dashboard.accounts.header.assign.to.partner',
] as const;

export const defaultFormFields: IUpsertAccountFormFields = {
  account: accountFormDefaultFormFields,
  address: addressFormDefaultFormFields,
};
