import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import { ERoutes } from '@enums';
import { AUTH0_SCOPES } from '@infrastructure';
import { IAuth0ProviderWithHistoryProps } from './auth0-provider-with-history.types';

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const ORGANIZATION_ID = process.env.REACT_APP_ORGANIZATION_ID;

export const Auth0ProviderWithHistory = ({
  children,
}: IAuth0ProviderWithHistoryProps): JSX.Element => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) =>
    navigate(appState?.returnTo || window.location.pathname);

  return (
    <Auth0Provider
      domain={DOMAIN}
      useRefreshTokens
      audience={AUDIENCE}
      clientId={CLIENT_ID}
      scope={AUTH0_SCOPES}
      organization={ORGANIZATION_ID}
      onRedirectCallback={onRedirectCallback}
      redirectUri={`${window.location.origin}/${ERoutes.DASHBOARD}`}
    >
      {children}
    </Auth0Provider>
  );
};
