import { useNavigate } from 'react-router-dom';

import { Error } from '@domain/error';
import { Button } from '@amperio/amperio-ui';

import { ERoutes } from '@enums';
import { useTranslation } from '@utils';
import { translationStrings } from './not-found.defaults';

export const NotFoundPage = (): JSX.Element => {
  const navigate = useNavigate();
  const translations = useTranslation(translationStrings);

  const redirectToHomepage = () => navigate(`/${ERoutes.DASHBOARD}`);

  return (
    <Error code="404" message={translations.errorPageNotFoundMessage}>
      <Button variant="contained" onClick={redirectToHomepage}>
        {translations.errorPageNotFoundBackToHomepageButton}
      </Button>
    </Error>
  );
};
