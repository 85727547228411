import { IAccountFormFields } from '../../interfaces';

export const translationStrings = [
  'common.form.field.error.required',
  'form.account.header',
  'form.account.name.label',
  'form.account.name.max.length.error',
  'form.account.surname.label',
  'form.account.surname.max.length.error',
  'form.account.email.label',
  'form.account.email.pattern.match.error',
  'form.account.email.max.length.error',
  'form.account.phone.number.label',
  'form.account.phone.number.pattern.match.error',
] as const;

export const accountFormDefaultFormFields: IAccountFormFields = {
  name: '',
  email: '',
  surname: '',
  phoneNumber: '',
};
