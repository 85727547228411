export default {
  // header
  'dashboard.accounts.header.add.event.button': 'Add event',
  'dashboard.accounts.header.add.account.button': 'Add account',
  'dashboard.accounts.header.assign.to.partner': 'Assign to partner',

  // list
  'dashboard.accounts.list.name.column.label': 'Name',
  'dashboard.accounts.list.location.column.label': 'Location',
  'dashboard.accounts.list.products.column.label': 'Products',
  'dashboard.accounts.list.date.column.label': 'Status date',
  'dashboard.accounts.list.status.column.label': 'Last status',

  // events table
  'dashboard.accounts.section.events.table.header': 'Planner',
  'dashboard.accounts.section.events.table.date': 'Date',
  'dashboard.accounts.section.events.table.products': 'Products',
  'dashboard.accounts.section.events.table.type': 'Type',
  'dashboard.accounts.section.events.table.status': 'Status',
  'dashboard.accounts.section.events.table.notes': 'Notes',
};
