import axios, { AxiosRequestConfig } from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const axiosConfig: AxiosRequestConfig = {
  timeout: 15_000,
  baseURL: API_ENDPOINT,
  timeoutErrorMessage: 'Request timeout.',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
};

export const axiosInstance = axios.create(axiosConfig);
