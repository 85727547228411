import { ICalendarProps } from '@interfaces';
import { ECalendarViews, EEventType } from '@enums';

export const translationStrings = [
  'calendar.popover.more',
  'calendar.more.expanded.label',
  'calendar.more.collapsed.label',
] as const;

export const calendarMockup = [
  {
    events: [
      {
        title: 'Maegan Skinner',
        start: '2022-01-08T14:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2022-01-08T14:30:00',
          product: 'Heating Pumps',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2022-01-08T15:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2022-01-08T15:30:00',
          product: 'Heating Pumps',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2022-01-08T16:20:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2022-01-08T16:20:00',
          product: 'Heating Pumps',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2022-01-08T16:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2022-01-08T16:30:00',
          product: 'Heating Pumps',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2022-01-08T17:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2022-01-08T17:30:00',
          product: 'Solar Panels',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2022-01-07T17:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          product: 'Heating Pumps',
          start: '2022-01-07T17:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-01-06T17:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          product: 'Heating Pumps',
          start: '2021-01-06T17:30:00',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-19T17:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-12-19T17:30:00',
          product: 'Solar Panels',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2021-12-21T13:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-12-21T13:30:00',
          product: 'Heating Pumps',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-18T17:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-12-18T17:30:00',
          product: 'Solar Panels',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-19T17:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-12-19T17:30:00',
          product: 'Heating Pumps',
          type: EEventType.MEETING,
        },
      },
      {
        title: 'Maegan Skinner',
        start: '2021-12-18T09:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-12-18T09:30:00',
          product: 'Solar Panels',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2021-12-18T12:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-12-18T12:30:00',
          product: 'Solar Panels',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Skylah Austin',
        start: '2021-01-06T12:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-01-06T12:30:00',
          product: 'Heating Pumps',
          type: EEventType.CALL,
        },
      },
      {
        start: '2021-01-07T08:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          start: '2021-01-07T08:30:00',
          product: 'Heating Pumps',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2022-01-08T08:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          product: 'Heating Pumps',
          start: '2022-01-08T08:30:00',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2021-12-29T18:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          product: 'Heating Pumps',
          start: '2021-12-29T18:30:00',
          type: EEventType.CALL,
        },
      },
      {
        title: 'Antoni Barton',
        start: '2021-12-06T16:30:00',
        classNames: ['calendar-event'],
        extendedProps: {
          product: 'Heating Pumps',
          start: '2021-12-29T16:30:00',
          type: EEventType.CALL,
        },
      },
    ],
  },
];

export const calendarSettings: {
  mobile: Pick<ICalendarProps, 'initialView' | 'dayCount' | 'dateIncrement'>;
  desktop: Pick<ICalendarProps, 'initialView' | 'dayCount'>;
} = {
  mobile: {
    dayCount: 7,
    dateIncrement: { days: 7 },
    initialView: ECalendarViews.MOBILE,
  },
  desktop: {
    initialView: ECalendarViews.MONTHLY,
  },
};
