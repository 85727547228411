import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@amperio/amperio-ui';

import { FullScreenLoading } from '@domain/loading';

import { ERoutes } from '@enums';
import { IGetConditionalRouteAttributes } from './get-conditional-route.types';

export const getConditionalRoute = ({
  element,
  authorize,
  Component,
  ...routerProps
}: IGetConditionalRouteAttributes) => {
  if (!Component) {
    return <Navigate to={ERoutes.NOT_FOUND} />;
  }

  if (authorize) {
    return withAuthenticationRequired(Component, {
      targetRoute: routerProps.path,
      onRedirecting: () => <FullScreenLoading />,
      onError: (error) => <Navigate to={`${ERoutes.FORBIDDEN}?message=${error?.message}`} />,
    })(routerProps);
  }

  return <Component {...routerProps} />;
};
