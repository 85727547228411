import { EEventType, EEventStatus, EProductCategory } from '@enums';

export const dataSourceMock = [
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.SALE,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.SALES_OPPORTUNITY,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.RESIGNATION,
    seller: 'Jim Jared',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.PLANNED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.MISSED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.SALE,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.RESIGNATION,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Jim Jared',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.REJECTED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.REJECTED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.MISSED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.SALES_OPPORTUNITY,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.PLANNED,
    seller: 'Jim Jared',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.REJECTED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.PLANNED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.REJECTED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.SALE,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.MEETING,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.SALES_OPPORTUNITY,
    seller: 'Jim Jared',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.SALE,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.PLANNED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Kaunas',
    status: EEventStatus.REJECTED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.RESIGNATION,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS]),
  },
  {
    name: 'Alessandro Robles',
    location: 'Klaipėda',
    status: EEventStatus.BID_SUBMITTED,
    seller: 'Jim Jared',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'John Smith',
    location: 'Klaipėda',
    status: EEventStatus.PLANNED,
    seller: 'Thomson',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.PHOTOVOLTAICS, EProductCategory.HEATING_PUMPS]),
  },
  {
    name: 'Demi-Lee Davila',
    location: 'Vilnius',
    status: EEventStatus.MISSED,
    seller: 'Pearl Cox',
    date: '14.11.2021',
    type: EEventType.CALL,
    notes: 'Lorem Ipsum is simply dummy text of the layout ',
    products: JSON.stringify([EProductCategory.FOIL_HEATERS, EProductCategory.HEATING_PUMPS]),
  },
];

export const translationStrings = [
  'dashboard.accounts.section.events.table.header',
  'dashboard.accounts.section.events.table.date',
  'dashboard.accounts.section.events.table.products',
  'dashboard.accounts.section.events.table.type',
  'dashboard.accounts.section.events.table.status',
  'dashboard.accounts.section.events.table.notes',
  'common.event.status.sale',
  'common.event.status.missed',
  'common.event.status.planned',
  'common.event.status.rejected',
  'common.event.status.resignation',
  'common.event.status.bid.submitted',
  'common.event.status.sales.opportunity',
] as const;

export const chunkOfData = [
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
  ...dataSourceMock,
];

export const eventStatusToChipColorMapper: Record<string, TChipColor> = {
  [EEventStatus.SALE]: 'green',
  [EEventStatus.MISSED]: 'red',
  [EEventStatus.PLANNED]: 'gray',
  [EEventStatus.REJECTED]: 'orange',
  [EEventStatus.RESIGNATION]: 'brown',
  [EEventStatus.BID_SUBMITTED]: 'purple',
  [EEventStatus.SALES_OPPORTUNITY]: 'blue',
};

export const getEventStatusToTranslationsMapper = (
  translations: Record<string, string>,
): Record<string, string> => ({
  [EEventStatus.SALE]: translations.commonEventStatusSale,
  [EEventStatus.MISSED]: translations.commonEventStatusMissed,
  [EEventStatus.PLANNED]: translations.commonEventStatusPlanned,
  [EEventStatus.REJECTED]: translations.commonEventStatusRejected,
  [EEventStatus.RESIGNATION]: translations.commonEventStatusResignation,
  [EEventStatus.BID_SUBMITTED]: translations.commonEventStatusBidSubmitted,
  [EEventStatus.SALES_OPPORTUNITY]: translations.commonEventStatusSalesOpportunity,
});
