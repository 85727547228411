export const translationStrings = [
  'dashboard.events.header.select.seller',
  'dashboard.events.list.header.calendar.switch',
  'dashboard.events.list.header.add.event.button',

  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
] as const;
