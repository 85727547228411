import { useState } from 'react';
import { IntlProvider } from 'react-intl';

import { ELocale } from '@enums';
import { ILocaleProviderProps } from './locale-provider.types';

import { LocaleContext, DEFAULT_LOCALE } from '@infrastructure';
import { translations } from '@infrastructure/i18n/translations';

export const LocaleProvider = ({ children }: ILocaleProviderProps) => {
  const [locale, setLocale] = useState<ELocale>(DEFAULT_LOCALE);

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={translations[locale]}>
      <LocaleContext.Provider
        value={{
          locale,
          setLocale,
          defaultLocale: DEFAULT_LOCALE,
        }}
      >
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};
