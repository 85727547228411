export enum ERoutes {
  ANY = '*',
  LOGIN = '/login',
  FORBIDDEN = '/403',
  NOT_FOUND = '/404',
  DASHBOARD = 'dashboard',
}

export enum EDashboardRoutes {
  LEADS = 'leads',
  EVENTS = 'events',
  ACCOUNTS = 'accounts',
}

export enum EDashboardEventsRoutes {
  LIST = 'list',
  UPSERT = 'upsert',
}

export enum EDashboardAccountsRoutes {
  LIST = 'list',
  UPSERT_EVENT = 'upsert-event',
  UPSERT_ACCOUNT = 'upsert-account',
}

export enum EDashboardLeadsRoutes {
  LIST = 'list',
  UPSERT_LEAD = 'upsert-lead',
}

export enum EUserClaim {
  READ_APPLICATION = 'read:application',
}

export enum ELocale {
  EN = 'en',
}

export enum ECalendarViews {
  MOBILE = 'mobile',
  WEEKLY = 'dayGridWeek',
  MONTHLY = 'dayGridMonth',
}

export enum ESortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum EEventType {
  CALL,
  MEETING,
  ABSENCE,
}

export enum EPaymentMethod {
  CASH = 'cash',
  CREDIT = 'credit',
}

export enum EProductCategory {
  FOIL_HEATERS = 'fh',
  PHOTOVOLTAICS = 'pv',
  HEATING_PUMPS = 'hs',
}

export enum ELevelOfInsulation {
  PASSIVE = 'passive',
  ENERGY_SAVING = 'energy-saving',
  WELL_INSULATED = 'well-insulated',
  MEDIUM_INSULATED = 'medium-insulated',
  POORLY_INSULATED = 'poorly-insulated',
}

export enum ECurrentTypeOfHeating {
  OTHER = 'other',
  GAS_STOVE = 'gas-stove',
  COAL_STOVE = 'coal-stove',
  ELECTRIC_HEATING = 'electric-heating',
}

export enum EInstallationSite {
  EAST = 'east',
  WEST = 'west',
  NORTH = 'north',
  SOUTH = 'south',
}

export enum ERoofOrGround {
  ROOF = 'roof',
  GROUND = 'ground',
}

export enum EMonth {
  JANUARY = 'january',
  FEBRUARY = 'february',
  MARCH = 'march',
  APRIL = 'april',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUGUST = 'august',
  SEPTEMBER = 'september',
  OCTOBER = 'october',
  NOVEMBER = 'novembar',
  DECEMBER = 'december',
}

export enum ELeadStatus {
  NEW = 'new',
  INVALID = 'invalid',
  CALLBACK = 'callback',
  DUPLICATE = 'duplicate',
  INTERESTED = 'interested',
  RESIGNATION = 'resignation',
}

export enum EEventStatus {
  SALE = 'sale',
  MISSED = 'missed',
  PLANNED = 'planned',
  REJECTED = 'rejected',
  RESIGNATION = 'resignation',
  BID_SUBMITTED = 'bid-submitted',
  SALES_OPPORTUNITY = 'sales-opportunity',
}
