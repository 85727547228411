import {
  Fab,
  Icon,
  Button,
  PlusIcon,
  Typography,
  ArrowLeftIcon,
  useDeviceDetect,
  useConfirmationModal,
} from '@amperio/amperio-ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StringParam, useQueryParam } from 'use-query-params';

import { LeadsForm, ILeadsFormFields, leadsFormDefaultFormFields } from '../../molecules';

import { useTranslation, usePrompt } from '@utils';
import { translationStrings } from './upsert-lead.defaults';

import { ELeadStatus } from '@enums';
import { IUpsertLeadProps } from './upsert-lead.types';

import './upsert-lead.styles.scss';

export const UpsertLead = ({ onBackClick, setSelectedLead }: IUpsertLeadProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const { confirm } = useConfirmationModal();
  const [isEditMode, setIsEditMode] = useState(true);
  const translations = useTranslation(translationStrings);
  const [leadNameQueryParam] = useQueryParam('leadName', StringParam);

  const { reset, control, formState, handleSubmit } = useForm<ILeadsFormFields>({
    mode: 'onChange',
    defaultValues: leadsFormDefaultFormFields,
  });

  usePrompt(
    translations.commonPromptUnsavedTitle,
    translations.commonPromptUnsavedLeave,
    formState.isDirty,
  );

  const onSubmit = () => {
    setIsEditMode(false);

    if (!leadNameQueryParam) {
      // TODO - send request to the server with the new lead data taken from form
      setSelectedLead({
        name: 'John Smith',
        phoneNumber: '667981765',
        city: 'Kraków',
        country: 'Polska',
        region: 'Małopolskie',
        date: '14.11.2021',
        partner: 'Chuck Norris',
        status: ELeadStatus.DUPLICATE,
      });
    }
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const handleCancel = () => {
    if (!formState.isDirty) {
      toggleEditMode();
      return;
    }

    confirm({
      title: translations.commonPromptUnsavedTitle,
      confirmationButtonProps: { autoFocus: true },
      description: translations.commonPromptUnsavedCancel,
    }).then(() => {
      reset(leadsFormDefaultFormFields);
      toggleEditMode();
    });
  };

  return (
    <form className="upsert-lead-wrapper" onSubmit={handleSubmit(onSubmit)}>
      <header className="upsert-lead-wrapper__header">
        {!deviceData.isMobile ? (
          <>
            <Button variant="outlined" onClick={onBackClick} startIcon={<ArrowLeftIcon />}>
              {translations.commonButtonsBack}
            </Button>
            <span>
              {!isEditMode ? (
                <Button variant="contained" onClick={toggleEditMode}>
                  {translations.commonButtonsEdit}
                </Button>
              ) : (
                <>
                  <Button variant="contained" disabled={!isEditMode} onClick={handleCancel}>
                    {translations.commonButtonsCancel}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!isEditMode || !formState.isDirty}
                  >
                    {translations.commonButtonsSave}
                  </Button>
                </>
              )}
            </span>
          </>
        ) : null}
        {deviceData.isMobile && formState.isDirty ? (
          <Fab type="submit" variant="extended">
            <Icon stroke="white">
              <PlusIcon />
            </Icon>
            <Typography strong as="span" color="white">
              {translations.commonButtonsSave}
            </Typography>
          </Fab>
        ) : null}
      </header>
      <section className="upsert-lead-wrapper__content">
        <LeadsForm control={control} isEditMode={isEditMode} />
      </section>
    </form>
  );
};
