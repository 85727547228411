import { useMemo } from 'react';
import { Scrollbar, useDeviceDetect } from '@amperio/amperio-ui';

import {
  MobileTopbar,
  MobileHeader,
  DesktopHeader,
  DesktopSidebar,
} from '@domain/dashboard/molecules';

import { IDashboardProps } from './dashboard.types';
import { getSidebarOptions, translationStrings } from './dashboard.defaults';

import { useTranslation } from '@utils';

import './dashboard.styles.scss';

export const DashboardTemplate = ({ children }: IDashboardProps): JSX.Element => {
  const { deviceData } = useDeviceDetect();
  const translations = useTranslation(translationStrings);

  const sidebarOptions = useMemo(() => getSidebarOptions(translations), [translations]);

  return (
    <div className="dashboard-wrapper">
      {deviceData.isMobile ? (
        <MobileTopbar options={sidebarOptions} />
      ) : (
        <DesktopSidebar options={sidebarOptions} />
      )}
      <section className="dashboard-wrapper__container">
        {deviceData.isMobile ? <MobileHeader /> : <DesktopHeader />}
        <div className="dashboard-wrapper__content">
          <Scrollbar height="100%">
            <div className="dashboard-wrapper__content-padding">{children}</div>
          </Scrollbar>
        </div>
      </section>
    </div>
  );
};
