import classnames from 'classnames';
import { Typography, CenterBlock } from '@amperio/amperio-ui';

import { IErrorProps } from './error.types';

import './error.styles.scss';

export const Error = ({ code, message, className, children }: IErrorProps): JSX.Element => (
  <CenterBlock className={classnames('error-wrapper', className)}>
    <Typography as="h1">{code}</Typography>
    <Typography as="h2">{message}</Typography>
    <section className="error-wrapper__actions">{children}</section>
  </CenterBlock>
);
