import { useState } from 'react';
import { Chip, DataGrid, Typography } from '@amperio/amperio-ui';

import {
  chunkOfData,
  translationStrings,
  eventStatusToChipColorMapper,
  getEventStatusToTranslationsMapper,
} from './accounts-table.defaults';
import { useTranslation } from '@utils';

import { IAccount } from '@interfaces';
import { ESortDirection } from '@enums';
import { IAccountsTableProps } from './accounts-table.types';

import './accounts-table.styles.scss';

export const AccountsTable = ({ onRowClick }: IAccountsTableProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const translations = useTranslation(translationStrings);

  const eventStatusToTranslationsMapper = getEventStatusToTranslationsMapper(translations);

  return (
    <DataGrid
      className="accounts-table-wrapper"
      onBodyRow={(record: unknown) => ({
        onClick: () => onRowClick(record as IAccount),
        className: 'accounts-table-wrapper__body-row',
      })}
      getContainer={() => document.getElementById('accounts-list-wrapper-id')}
      isLoading={isLoading}
      getDataFromServer={() =>
        new Promise((resolve) => {
          setIsLoading(true);

          setTimeout(() => resolve(chunkOfData), Math.max(Math.random() * 3000, 300));
        }).then((data) => {
          setIsLoading(false);
          return data;
        })
      }
      defaultSortOrder={ESortDirection.ASC}
      defaultOrderByKey="name"
      columns={[
        {
          minWidth: 200,
          field: 'name',
          headerName: translations.dashboardAccountsListNameColumnLabel,
          renderBodyCell: (value) => (
            <Typography as="span" strong>
              {value}
            </Typography>
          ),
        },
        {
          minWidth: 175,
          sortable: true,
          field: 'location',
          headerName: translations.dashboardAccountsListLocationColumnLabel,
        },
        {
          minWidth: 150,
          field: 'date',
          sortable: true,
          headerName: translations.dashboardAccountsListDateColumnLabel,
        },
        {
          minWidth: 175,
          sortable: true,
          field: 'status',
          headerName: translations.dashboardAccountsListStatusColumnLabel,
          renderBodyCell: (value) => (
            <Chip
              size="small"
              color={eventStatusToChipColorMapper[value]}
              label={eventStatusToTranslationsMapper[value]}
            />
          ),
        },
      ]}
    />
  );
};
