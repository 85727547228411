import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import { AccountsList } from './organisms';
import { UpsertEvent, UpsertAccount } from '../shared';

import { IAccount } from '@interfaces';
import { ERoutes, EDashboardAccountsRoutes } from '@enums';

export const Accounts = (): JSX.Element => {
  const navigate = useNavigate();

  const onBackButtonClick = () => navigate(-1);

  // accounts list - actions
  const onAccountsListRowClick = (selectedAccount: IAccount) =>
    navigate(`${EDashboardAccountsRoutes.UPSERT_ACCOUNT}?accountName=${selectedAccount.name}`);

  const onAccountsListAddAccountButtonClick = () =>
    navigate(EDashboardAccountsRoutes.UPSERT_ACCOUNT);

  // upsert account - actions
  const onUpsertAccountAddEventClick = () => navigate(EDashboardAccountsRoutes.UPSERT_EVENT);

  const onUpsertAccountSetSelectedAccount = (selectedAccount: IAccount) =>
    navigate(`${EDashboardAccountsRoutes.UPSERT_ACCOUNT}?accountName=${selectedAccount.name}`);

  return (
    <Routes>
      <Route
        path={EDashboardAccountsRoutes.LIST}
        element={
          <AccountsList
            onTableRowClick={onAccountsListRowClick}
            onAddNewAccountButtonClick={onAccountsListAddAccountButtonClick}
          />
        }
      />
      <Route
        path={EDashboardAccountsRoutes.UPSERT_ACCOUNT}
        element={
          <UpsertAccount
            onBackClick={onBackButtonClick}
            onAddEventClick={onUpsertAccountAddEventClick}
            setSelectedAccount={onUpsertAccountSetSelectedAccount}
          />
        }
      />
      <Route
        path={EDashboardAccountsRoutes.UPSERT_EVENT}
        element={<UpsertEvent onBackClick={onBackButtonClick} />}
      />
      <Route path={ERoutes.ANY} element={<Navigate replace to={ERoutes.NOT_FOUND} />} />
    </Routes>
  );
};
